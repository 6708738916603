import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

const Navbar = () => {
    const { user, logout } = useContext(AuthContext);
    const [menuActive, setMenuActive] = useState(false);
    const [loadProfileOptions, setLoadProfileOptions] = useState(false);
    const [loadStaticOptions, setLoadStaticOptions] = useState(false);
    const [right, setRight] = useState('10.2rem');
    const [scrolled, setScrolled] = useState(false);
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(storedCart);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleProfileClick = (option) => {
        setLoadProfileOptions(false);
        setLoadStaticOptions(false);
        if (option === 'profile') {
            setLoadProfileOptions(true);
            setRight('10.2rem');
        } else {
            setLoadStaticOptions(true);
            setRight('1.2rem');
        }
        setMenuActive(!menuActive);
    };

    const gotocart = () => {
        navigate('/cart');
    }

    const gotologin = () => {
        navigate('/login');
    }

    const goToProfile = () => {
        navigate('/profile');
    }

    const gotohome = () => {
        navigate('/');
    }

    const gotoOurStory = () => {
        navigate('/ourstory');
    }

    const gotoProcess = () => {
        navigate('/process');
    }

    const gotoOurFarm = () => {
        navigate('/ourfarm');
    }

    const gotoGallery = () => {
        navigate('/gallery');
    }

    const gotoContact = () => {
        navigate('/contactus');
    }

    const gotoProduct = () => {
        navigate('/Product');
    }


    const handleLogout = () => {
        logout();
        setCartItems([]);
        localStorage.removeItem('cart');
        navigate('/');
    };

    return (
        <nav className={scrolled ? 'scrolled' : ''}>
            <div className="overlay">
                <img onClick={gotohome} src="../images/logo-icon.webp" alt="Whyte Farms" height={'85px'} style={{ marginTop: '10px', cursor: 'pointer' }} />
            </div>
            <div className="profile" onClick={() => handleProfileClick('profile')}>
                <div className="user user-1">
                    <img className="user-icon" src="../images/user2.png" alt="Whyte Farms" height={'40px'} style={{ marginTop: '5px', marginLeft: "2rem", cursor: "pointer" }} />
                    <img className="user-icon user-icon-2" onClick={gotocart} src="../images/cart2.png" alt="Whyte Farms" height={'40px'} style={{ marginTop: '5px', marginLeft: "2rem", cursor: "pointer" }} />
                    {cartItems.length > 0 ? <span className='badge badge-warning' id='lblCartCount'>{cartItems.length}</span> : <></>}
                </div>
            </div>
            <div className="user user-2" onClick={() => handleProfileClick('static')}>
                <img className="user-toggle" src="../images/tog.png" alt="Whyte Farms" height={'25px'} style={{ marginTop: '5px', marginLeft: "2rem", cursor: "pointer" }} />
            </div>
            <div className={`menu ${menuActive ? 'active' : ''}`} style={{ right: right }}>
                {loadProfileOptions && <ul>
                    <li>{user ? <a onClick={goToProfile}><i className="ph-bold ph-user"></i>&nbsp;Profile</a> : <a onClick={gotologin}><i className="ph-bold ph-user"></i>&nbsp;Login</a>}</li>
                    {user && <li><a onClick={handleLogout} ><i className="ph-bold ph-gear-six"></i>&nbsp;Logout</a></li>}
                </ul>}
                {loadStaticOptions && <ul>
                    <li><a onClick={gotohome}>Home</a></li>
                    <li><a onClick={gotoOurStory}>Our Story</a></li>
                    <li><a onClick={gotoOurFarm}>Our Farm</a></li>
                    <li><a onClick={gotoProduct}>Our Product</a></li>
                    <li><a onClick={gotoContact}>Contact Us</a></li>
                </ul>}
            </div>
        </nav>
    );
};

export default Navbar;
